/**
 * getRealTSsensorValue
 * @param {string} reference
 * @returns string
 */
export const getRealSensorTS = (reference) => {
  const lowerName = reference?.toLowerCase ? reference?.toLowerCase() : reference;
  switch (lowerName) {
    case 'h2s':
      return 'h2s_µg_m3';
    case 'nh3':
      return 'nh3_µg_m3';
    case 'tvoc':
      return 'pid_µg_m3';
    case 'pm10':
      return 'pm10';
    case 'odors':
    case 'odours':
      return 'odor_m3'; // odorDistance, odors, odor, ordorUnit or duo
    case 'so2':
      return 'so2_µg_m3';
    case 'nox':
      return 'no2_µg_m3';
    case 'pm2_5':
      return 'pm2_5';
    default:
      return reference;
  }
};

export const parseRealSensorToIdiotPreviousConfig = (list, sensors) => {
  list.forEach((item) => {
    // sensors is an array of sensor that contains some info that list of item do not have pulled from the db
    // sensors in an array of object that have like [{ data_type: '1', category: 'temperature', ...}]
    // item name correspond to data_type
    // to be used by getNewSensorList in analysisTools.js
    const realSensors = sensors.filter((sensor) => sensor.data_type === item.name && sensor.category === item.category);
    if (realSensors.length > 0) {
      item.realSensors = realSensors;
      item.unit = realSensors[0]?.unit || item.unit || '-';
      item.category = realSensors[0]?.category || item.category || undefined;
      item.precision = realSensors[0]?.precision || item.precision || '0.0';
      realSensors[0].found = true;
    }
  });
  sensors
    .filter((s) => !s?.found)
    .forEach((s) =>
      list.push({
        realSensors: s,
        unit: s.unit,
        category: s.category,
        precision: s.precision,
        name: s.data_type
      })
    );
  return list;
};
